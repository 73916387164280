<template>
    <page
        id="clients-list"
        :title="$root.translate('Client:::Clients', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('Client:::Clients', {}, 2) }}
                <default-button
                    :title="$root.translate('New Client')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="clients-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :export-url="export_url"
            :page-sizes="[25, 50, 100, 0]"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @per-page="set_page_size"
            @click="record_edit(entity, $event.uuid)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        id="service_id"
                        name="service_id"
                        :value="state.service_id"
                        :options="service_filters"
                        :empty-value="0"
                        :empty-label="$root.translate('Filter by service(s)')"
                        :required="false"
                        @input="filter_by('service_id', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="status"
                        name="status"
                        :value="state.filter"
                        :options="filters"
                        label-field="title"
                        empty-value=""
                        :empty-label="$root.translate('Any Status')"
                        :required="false"
                        @input="filter('status', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:buttons
            >
                <default-button
                    flavor="link"
                    :waiting="resyncing"
                    :title="$root.translate('Re-sync all clients')"
                    class="ml-2"
                    @click="resync"
                >
                    <open-icon glyph="sync" />
                </default-button>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <div class="text-gray">{{ status_for_row(row) }}</div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'status'">{{ status_for_row(row) }}</span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"

import { is_page, is_remote_data_table_source, crud_utilities } from "@/nibnut/mixins"

import {
    FormDropdown,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities],
    components: {
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    methods: {
        pre_load () {
            const filters = this.filters
            if((this.state.filter === null) && !!filters && !!filters.length) this.set_state_value("filter", filters.find(filter => filter.title.match(/^active/i)).id)
        },
        status_for_row (row) {
            const filters = this.filters
            const filter = filters.find(filter => filter.id === row.status)
            return filter ? filter.title : ""
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            if(this.state.service_id) {
                rows = rows.filter(row => {
                    if(this.state.service_id === "true") return !!row.active_service_ids.length
                    return row.active_service_ids.indexOf(this.state.service_id) >= 0
                })
            }
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        resync () {
            this.resyncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "client",
                    action: "resync",
                    data: {
                        ...this.state,
                        fields: this.fields,
                        relation_ids: this.relation_ids
                    }
                }
            ).then(() => {
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.resyncing = false
            })
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        },
        filters () {
            return Object.values(this.constants("client_statuses"))
        },
        service_filters () {
            const services = orderBy(this.entity_records("service"), "name", "asc")
            services.unshift({ id: "true", name: "At least one service" })
            return services
        }
    },
    data () {
        return {
            entity: "client",
            relation_ids: ["service"],
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                status: { label: "Status", sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 25,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "status",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                service_id: 0
            },
            resyncing: false
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
